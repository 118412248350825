const theme = {
  primary: "#013727",
  secondary: "#4D4D4F",
  accent: "#fff", //link text
  error: "#133526", //item se
  ////secondary: "#394246lected
  info: "#51702d",
  success: "#061a35",
  //warning: "#00F"
  warning: "#fcfbf1", //marker text

  header: "#a3bba0",
  tabs: "#96a992",

  mediaButtonColor: "#013727",
  filterLegendButtonColor: "#013727",
  dialogActionButtonColor: "013727",

  logoTextColor: "#e9e3cd",
  filterButtonColor: "#717C44",
  mainMenuBarColor: "#717C44",
  menuBarColor: "#013727",
  langButtonColor: "#013727",
  dialogToobarColor: "#717C44",
  //tabSliderColor: "#9f9262"
  tabSliderColor: "#fff",
  logoColor: "#013727"

};
const global = {
  appSettings: {
    map: {
      accessToken:
        //  "pk.eyJ1IjoiaW5mby1tYXB0aXZhdGUiLCJhIjoiY2xmbTF1Ym9wMDdmczQxbW9vcmdmYm92cCJ9.UgrbRYg9Chk1JJzNvgnQNw", //maptivate key;
        "pk.eyJ1IjoibWFwdGl2YXRlem9vdmljIiwiYSI6ImNsemFvdXVsazBodGIybG43dzl6YjN0dDQifQ.xMFfziOAsmAp12VNhWEKqQ", //maptivate service;
      options: {
        //style: "mapbox://styles/info-maptivate/clqbqhfqp003t01rc54rtcitk", //main style
        style: "mapbox://styles/maptivatezoovic/cm0nfb5xb001s01pq266x69kj", //service account style

        //other styles:
        //style: "mapbox://styles/info-maptivate/clocbzi7k00bh01rc6s3bclm8", //main", //main v3x
        //style: "mapbox://styles/info-maptivate/clqbl0cng003t01rjh6zydrgg", //v2.3
        center: [145.051366, -36.32323],
        maxZoom: 24,
        minZoom: 0,
        pitch: 40,
        bearing: 90,
        //bearing: 0,
        zoom: 15.8
      },
      bounds: [
        //[144.65128290753978, -36.63101853195932], // Southwest coordinates
        //[145.49014584845827, -36.02632420251042] // Northeast coordinates
        
        [140, -39], // Southwest coordinates
        [150, -33] // Northeast coordinates
      ],
      defaultSetup: {
        center: [145.051366, -36.32323],
        zoom: 15.5,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 10,
        bearing: 90
        //bearing: 0
      },
      flyToBounds: [
        [145.05219370156937, -36.32101125352809],
        [145.04527041451746, -36.326800929298706]
      ],
      disableRotationAndBearing: false
    },

    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 18,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 0.7
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "mv_label",

        useCircle: true,
        scaling: {
          minZoom: 16.9,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: true,
        hoverState: true,
        haveToolTip: false,
        styles: {
          //unclustered_paint: { "circle-color": "#ff0000" },
          //clustered_paint: { "circle-color": "#354d64" },

          unclustered_icon_size: {
            stops: [
              [16, 7.5 / 35],
              [17, 15 / 35]
            ]
          },
          unclustered_radius: {
            stops: [
              [16, 7.5],
              [17, 15]
            ]
          },
          clustered_radius: {
            stops: [
              [16, 10],
              [17, 20]
            ]
          }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: true,
          //clusterMaxZoom: 18,//setting this to null will make it work at 18, if i set it to 18 seems to break it.
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },

    zoomToOnSelect: 18,
    mainTabDark: true,
    listFilterTabDark: true,

    titleImageLogo: true,
    titleImageLogoColor: true,
    titleTextColorClass: "menuBarColor--text"
  }
};

module.exports = {
  global,
  theme
};
