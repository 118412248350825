const theme = {};
const global = {
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoibWFwdGl2YXRlc2VydmljZWFjY291bnQiLCJhIjoiY2xyNXZ0d3B0MXpjbTJscWJ3cWhkbGlvMiJ9.yMuBfMksI3dAad1PytrHyg", //maptivate service;
      options: {
        style:
          "mapbox://styles/maptivateserviceaccount/clrfz4qkz00ab01pd7jadf5jx", //service account style

        center: [144.9515, -37.7841],
        maxZoom: 24,
        minZoom: 0,
        pitch: 40,
        bearing: -45,
        //bearing: 0,
        zoom: 15.8
      },
      bounds: [
         //[144.85036143131515, -37.8533280298009], // Southwest coordinates
        //[145.0615299795112, -37.712797163701886] // Northeast coordinates
        
        [140, -39], // Southwest coordinates
        [150, -33] // Northeast coordinates
      ],
      defaultSetup: {
        center: [144.9515, -37.7841],
        zoom: 20,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 30,
        bearing: -45
        //bearing: 0
      },
      flyToBounds: [
        [144.94990601491298, -37.78597242494533],
        [144.95311396439007, -37.78286633040725]
      ],
      disableRotationAndBearing: false
    },

    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 16.7,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 0.7
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "mv_label",

        useCircle: true,
        scaling: {
          minZoom: 16.9,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: true,
        hoverState: true,
        haveToolTip: false,
        styles: {
          //unclustered_paint: { "circle-color": "#ff0000" },
          //clustered_paint: { "circle-color": "#354d64" },

          unclustered_icon_size: {
            stops: [
              [16, 7.5 / 35],
              [17, 15 / 35]
            ]
          },
          unclustered_radius: {
            stops: [
              [16, 7.5],
              [17, 15]
            ]
          },
          clustered_radius: {
            stops: [
              [16, 10],
              [17, 20]
            ]
          }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: true,
          //clusterMaxZoom: 17,//setting this to null will make it work at 18, if i set it to 18 seems to break it.
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },

    zoomToOnSelect: 18,
    mainTabDark: false,
    listFilterTabDark: false,
    langButtonTextDark: false,

    titleImageLogo: true,
    titleImageLogoColor: false
  }
};

module.exports = {
  global,
  theme
};
