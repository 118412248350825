<template>
  <div>
    <v-card>
      <v-data-table
        class="reviewTableAct"
        :headers="
          showContent
            ? [
                {
                  text: 'Actions',
                  value: 'actions',
                  filterable: false,
                  sortable: false
                },
                {
                  text: 'wp post id',
                  value: 'post_id',
                  filterable: false,
                  width: '100'
                },

                {
                  text: 'Point Name',
                  value: 'Point_Name',
                  filterable: true,
                  width: '200'
                },

                {
                  text: 'type',
                  value: 'section_type',
                  filterable: true
                },
                {
                  text: 'layer',
                  value: 'section_slug',
                  filterable: true
                },

                {
                  text: 'icon_img',
                  value: 'icon_obj',
                  filterable: false,
                  sortable: false
                },

                {
                  text: 'content',
                  value: 'content',
                  filterable: false,
                  sortable: false
                },

                {
                  text: 'details',
                  value: 'details',
                  filterable: false,
                  sortable: false
                },
                {
                  text: 'Media',
                  value: 'media',
                  filterable: false,
                  width: '1500'
                }
              ]
            : showThumbs
            ? [
                {
                  text: 'Actions',
                  value: 'actions',
                  filterable: false,
                  sortable: false
                },
                {
                  text: 'wp post id',
                  value: 'post_id',
                  filterable: false,
                  width: '100'
                },

                {
                  text: 'Point Name',
                  value: 'Point_Name',
                  filterable: true,
                  width: '200'
                },
                {
                  text: 'Media',
                  value: 'media',
                  filterable: false
                }
              ]
            : [
                {
                  text: 'Actions',
                  value: 'actions',
                  filterable: false,
                  sortable: false
                },
                { text: 'wp post id', value: 'post_id', filterable: false },
                {
                  text: 'coordinates',
                  value: 'hasCoordinates',
                  filterable: false
                },
                {
                  text: 'Content Text',
                  value: 'hasContentText',
                  filterable: false
                },
                {
                  text: 'Marker Number',
                  value: 'Site_Marker_Number',
                  filterable: true
                },
                { text: 'Point Name', value: 'Point_Name', filterable: true },

                {
                  text: 'type',
                  value: 'section_type',
                  filterable: true
                },
                {
                  text: 'layer',
                  value: 'section_slug',
                  filterable: true
                },
                {
                  text: 'icon_img',
                  value: 'icon_obj',
                  filterable: false,
                  sortable: false
                },

                {
                  text: 'icon title',
                  value: 'icon_obj.title',
                  filterable: false
                },
                {
                  text: 'list_img',
                  value: 'list_image_obj',
                  filterable: false,
                  sortable: false
                },
                {
                  text: 'media count',
                  value: 'attachedMediaCount',
                  filterable: false
                },
                {
                  text: 'Media',
                  value: 'media',
                  filterable: false
                },

                {
                  text: 'meta data count',
                  value: 'structuredDataCount',
                  filterable: false
                },

                {
                  text: 'details',
                  value: 'details',
                  filterable: false,
                  sortable: false
                }
              ]
        "
        :items="markerListData"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-options': [itemsPerPage, ...itemPerPageOption]
        }"
        :search="
          JSON.stringify({
            markerSearch,
            selectedSectionTypes,
            selectabledLayers
          })
        "
        :custom-filter="filterFunction"
      >
        <template v-slot:item.actions="{ item }">
          <ItemDialog :data="item" />
        </template>

        <template v-slot:item.actions="{ item }">
          <ItemDialog :data="item" />
        </template>

        <template v-slot:item.details="{ item }">
          <ItemDialog :title="item.structuredDataCount" :data="item" />
        </template>

        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-form class="topForm">
            <div class="d-flex flex-row">
              <v-checkbox
                class="mb-0 pb-0"
                v-model="showContent"
                @click="showThumbs = false"
                :label="`Show Body Text`"
              ></v-checkbox>

              <v-checkbox
                class="mb-0 pb-0"
                v-model="showThumbs"
                @click="showContent = false"
                :label="`Media Review`"
              ></v-checkbox>

              <div class="mt-4 ml-6">
                type:
              </div>
              <v-select
                v-model="selectedSectionTypes"
                :items="sectionTypes"
                label="Section Types"
                multiple
                solo
                class="mx-4 mr-4"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedSectionTypes.length - 1 }} others)
                  </span>
                </template>
              </v-select>

              <div class="mt-4 ml-6">
                layer:
              </div>
              <v-select
                v-model="selectabledLayers"
                :items="selectableLayers"
                label="Section Types"
                multiple
                solo
                class="mx-4 mr-4"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectabledLayers.length - 1 }} others)
                  </span>
                </template>
              </v-select>

              <v-text-field
                v-model="markerSearch"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                class="mx-4 mr-4"
              ></v-text-field>

              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                :items-per-page-options="[itemsPerPage, ...itemPerPageOption]"
                items-per-page-text="Rows per page"
              />
            </div>
          </v-form>
        </template>

        <template v-slot:item.post_id="{ item }">
          <div>
            <a
              :href="`${appSettings.wpEditSite}${item.post_id}&action=edit`"
              target="_blank"
            >
              edit: {{ item.post_id }}
            </a>
          </div>
        </template>

        <template v-slot:item.content="{ item }">
          <div>
            <h3>{{ item.Point_Name }}</h3>
            <div
              v-show="item.content"
              class="pb-10"
              style="border:1px solid black;width:300px;margin-bottom:10px; padding:0 8px"
              v-html="item.content"
            ></div>
          </div>
        </template>
        <template v-slot:item.icon_obj="{ item }">
          <div class="OmStatus">
            <v-avatar class="profile" :size="iconSize" tile>
              <v-img :src="item.icon_obj.marker"></v-img>
            </v-avatar>
          </div>
        </template>
        <template v-slot:item.list_image_obj="{ item }">
          <div
            class="OmStatus"
            v-show="
              item.list_image_obj &&
                item.icon_obj &&
                item.list_image_obj.marker != item.icon_obj.marker
            "
          >
            <v-avatar class="profile" :size="iconSize" tile>
              <v-img :src="item.list_image_obj.marker"></v-img>
            </v-avatar>
          </div>
        </template>

        <template v-slot:item.media="{ item }">
          <div>
            <div
              v-if="showThumbs"
              class="reviewMediaWrapper d-flex flex-row  flex-wrap"
            >
              <div
                v-for="(mediaItem, index) in item.media"
                :key="`${item.post_id}_media_${index}`"
                style="border:1px solid #aaa;width:466px;margin:2px;padding:8px"
              >
          

                <h1>{{ mediaItem.title }}</h1>

                <a
                  :href="`${appSettings.wpEditSite}${mediaItem.ID}&action=edit`"
                  target="_blank"
                >
                  edit media item: {{ mediaItem.ID }}
                </a>

                <div>
                  mv_vertical_focus:
                  {{
                    mediaItem.mv_vertical_focus
                      ? mediaItem.mv_vertical_focus
                      : "unset"
                  }}
                </div>
                <div v-show="mediaItem.mv_horizontal_focus">
                  mv_horizontal_focus: {{ mediaItem.mv_horizontal_focus }}
                </div>
                <div v-show="mediaItem.aspectRatiosClass">
                  aspectRatiosClass: {{ mediaItem.aspectRatiosClass }}
                </div>

                <br />

                <h3>Thumbnail:</h3>
                <img
                  :width="200"
                  :title="
                    `${JSON.stringify({
                      title: mediaItem.title,
                      slug: mediaItem.slug,
                      caption: mediaItem.caption
                    })}`
                  "
                  :src="mediaItem.src_thumb"
                />

                <v-img :src="mediaItem.src_thumb"></v-img>

                <br />
                <div
                  :class="{
                    focusTop: mediaItem.mv_vertical_focus == 'top',
                    focusBottom: mediaItem.mv_vertical_focus == 'bottom',
                    focusTopCenter: mediaItem.mv_vertical_focus == 'top_center',
                    focusBottomCenter:
                      mediaItem.mv_vertical_focus == 'bottom_center',
                    focusLeft: mediaItem.mv_horizontal_focus == 'left',
                    focusRight: mediaItem.mv_horizontal_focus == 'right',
                    tallAspectImage:
                      mediaItem.aspectRatiosClass == 'tallAspectImage',
                    mediumTallAspectImage:
                      mediaItem.aspectRatiosClass == 'mediumTallAspectImage'
                  }"
                >
                  <h3>Side More Info Image:</h3>

                  <div style="border:1px solid grey;width:450px">
                    <v-img
                      :height="'190px'"
                      :width="'450px'"
                      :src="mediaItem.src_thumb"
                      eager
                    ></v-img>
                  </div>

                  <h3>Popup Image:</h3>

                  <div style="border:1px solid grey;width:285px">
                    <v-img
                      :height="'150px'"
                      :width="'285px'"
                      :src="mediaItem.src_thumb"
                      eager
                    ></v-img>
                  </div>
                </div>

                <div
                  class="enableAltAspectRation"
                  v-if="
                    mediaItem.aspectRatiosClass == 'tallAspectImage' ||
                      mediaItem.aspectRatiosClass == 'mediumTallAspectImage'
                  "
                >
                  <h3>Side More Tall Letterbox Image: :</h3>

                  <div
                    :class="{
                      focusTop: mediaItem.mv_vertical_focus == 'top',
                      focusBottom: mediaItem.mv_vertical_focus == 'bottom',
                      focusTopCenter:
                        mediaItem.mv_vertical_focus == 'top_center',
                      focusBottomCenter:
                        mediaItem.mv_vertical_focus == 'bottom_center',
                      focusLeft: mediaItem.mv_horizontal_focus == 'left',
                      focusRight: mediaItem.mv_horizontal_focus == 'right',
                      tallAspectImage:
                        mediaItem.aspectRatiosClass == 'tallAspectImage',
                      mediumTallAspectImage:
                        mediaItem.aspectRatiosClass == 'mediumTallAspectImage'
                    }"
                  >
                    <div style="border:1px solid grey;width:450px">
                      <v-img
                        :height="'190px'"
                        :width="'450px'"
                        :src="mediaItem.src_thumb"
                        eager
                      ></v-img>
                    </div>
                    <br />
                  </div>
                </div>

                <br />
                <hr />
              </div>
            </div>

            <div v-if="!showThumbs">
              <a
                v-for="(mediaItem, index) in item.media"
                :key="`${item.post_id}_media_${index}`"
                :href="mediaItem.src_gallery"
                target="_blank"
                style=";padding:2px;"
              >
                <v-avatar
                  class="profile"
                  :size="iconSize"
                  tile
                  style="border:1px solid black"
                >
                  <v-img
                    :title="
                      `${JSON.stringify({
                        title: mediaItem.title,
                        slug: mediaItem.slug,
                        caption: mediaItem.caption
                      })}`
                    "
                    :src="mediaItem.size.mv_icon_thumb[0]"
                  ></v-img>
                </v-avatar>
              </a>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import ItemDialog from "./ItemDialog.vue";

export default {
  name: "allMapItems_TableDataView",
  components: { ItemDialog },

  props: {
    itemsPerPage: Number,
    itemPerPageOption: Array
  },

  data() {
    return {
      showContent: false,
      showThumbs: false,
      markerSearch: "",
      iconSize: 60,
      selectedSectionTypes: [],
      selectabledLayers: []
    };
  },
  methods: {
    filterFunction(value, search, item) {
      search = JSON.parse(search);

      //selectedSectionTypes
      //selectabledLayers

      let layer =
        !search.selectabledLayers.length ||
        search.selectabledLayers.includes(item.section_slug);

      let mainFilter =
        value != null &&
        search.markerSearch != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.markerSearch.toLocaleUpperCase()) !== -1;

      return mainFilter && layer;
    }
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      markerSections: state => state.markers.sections,

      markerList: state => state.markers.markerList,
      geoJson_signage: state => state.markers.geoJson_signage,
      geoJson_artwork: state => state.markers.geoJson_artwork
    }),
    sectionTypes() {
      let section_types = [];
      for (let section in this.markerSections) {
        section_types.push(this.markerSections[section].section_type);
      }
      return [...new Set(section_types)];
    },
    selectableLayers() {
      let layers = [];
      if (this.selectedSectionTypes?.length) {
        for (let section in this.markerSections) {
          if (
            this.selectedSectionTypes.includes(
              this.markerSections[section].section_type
            )
          ) {
            layers.push(section);
          }
        }
      }
      return layers;
    },

    markerListData() {
      let dataList = [
        ...this.markerList.data.features,
        ...this.geoJson_signage.data.features,
        ...this.geoJson_artwork.data.features
      ];

      return dataList.map(row => {
        return {
          ...row.properties,
          attachedMediaCount: row?.properties?.media?.length
            ? row?.properties?.media?.length
            : 0,

          structuredDataCount: row?.properties?.structuredData
            ? _.size(row?.properties?.structuredData)
            : 0,

          coordinates: row?.geometry?.coordinates,
          content: row?.properties?.content?.rendered,
          hasContentText: row?.properties?.content?.rendered ? "yes" : "no",
          hasCoordinates:
            !isNaN(row?.properties?.Longitude) &&
            !isNaN(row?.properties?.Latitude)
              ? "yes"
              : "no"
        };
      });
    }
  },

  watch: {
    /*sectionTypes: function(value) {
      this.selectedSectionTypes = [...value];
    },*/
    selectableLayers: function(value) {
      this.selectabledLayers = [...value];
    }
  }
};
</script>

<style>
.reviewTableAct .v-data-table__wrapper {
  padding-top: 85px;
}
.reviewTableAct form.v-form.topForm {
  position: fixed;
  top: 64px;
  left: 0;
  background: #bbb;
  width: 100%;
  z-index: 100;
}

.reviewTableAct tr td {
  vertical-align: top;
  padding-top: 5px !important;
}
</style>
