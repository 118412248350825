<template>
  <div class="sinageListWrapper">
    <div v-for="(category, catKey) in categoryItemList" :key="catKey">
      <v-list flat>
        <v-list-item>
          <v-list-item-avatar size="50" tile>
            <div style="height: 43px; width: 50px; overflow:hidden;">
              <img
                style="height: 75px; width: 50px; object-fit: contain;"
                :src="category.setup.list_image_obj.marker"
                :aria-label="`${category.title}`"
              />
            </div>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="text-header text-wrap font-weight-medium font-weight-normal secondary--text notranslate"
              v-text="category.title"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            dense
            v-for="(site, i) in category.items"
            :key="i"
            @click="panToMaker(site)"
            :title="site.main"
          >
            <v-list-item-avatar tile size="30" class="signListAvatar">
              <img
                style=""
                v-if="
                  site.properties &&
                    site.properties.list_image_obj &&
                    site.properties.list_image_obj.marker
                "
                :src="site.properties.list_image_obj.marker"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <SignageListCustomItem :site="site"> </SignageListCustomItem>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import SignageListCustomItem from "./SignageListCustomItem.vue";

import { mapState } from "vuex";
import { eventBus } from "./../../main";

const moment = require("moment");

export default {
  name: "SignageList",
  props: {
    showLayers: Array
  },
  components: {
    SignageListCustomItem
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      geoJson_signage: state => state.markers.geoJson_signage,

      categoryLookup: state => state.markers.categoryLookup,

      markerImages: state => state.markers.markerImages

      /*

      sidebarSite: state => state.navigation.sidebarSite,
      markerList: state => state.markers.markerList,

      baseurllocation: state => state.navigation.baseurllocation,
      layers: state => state.filters.layers,
      applicationTypeFilters: state => state.filters.applicationTypeFilters
      //defaultLayers: state => state.filters.defaultLayers
      
      */
    }),
    signageList() {
      return this.geoJson_signage.data.features;
    },
    categoryItemList() {
      let catList = {};
      let validCats = [];
      try {
        if (this.showLayers && this.showLayers[0]) {
          this.showLayers.forEach(catSlug => {
            if (this.categoryLookup[catSlug]) {
              let thisCat = this.categoryLookup[catSlug];
              let { title, section_slug, section_type } = thisCat;
              catList[catSlug] = {
                title,
                section_slug,
                section_type,
                setup: thisCat,
                items: []
              };
              validCats.push(catSlug);
            }
          });
        }
      } catch (error) {
        //console.log("error!!", error);
      }

      validCats.forEach(catSlug => {
        let items = this.signageList.filter(maker => {
          return maker?.properties?.section_slug === catSlug;
        });

        items.forEach(item => {
          try {
            let name = item.properties.name;

            let main = name;
            try {
              main = main
                .replace("Keeper Talks - ", "")
                .replace("Keeper Talks", "")
                .replace(" - ", "");
            } catch (error) {
              //do nothing;
            }

            item.main = `Keeper Talk - ${main}`;

            let [title, time, extra] = main.split("|");
            item.timeInMins = 10000000;

            item.title = title;

            try {
              if (extra) {
                item.title = title.trim() + " " + extra.trim();
              }
            } catch (error) {
              //do nothing;
            }

            item.time = time;
            item.hasTitleAndTime = true;
          } catch (e) {
            //do nothing;
            //          console.log("error!!!", e);
          }
        });

        let extraItems = [];

        items.forEach(item => {


                            let newItem = JSON.parse(JSON.stringify(item));

          try {
            if (item?.time?.toLowerCase()?.includes("and")) {
              try {
                let times = item.time.split("and");

                item.time = times[0];

                if (times[0].includes("(")) {
                  let extra = times[0].split("(");
                  item.time = extra[0];

                  item.title = item.title.trim() + " (" + extra[1];
                }



                if (times[1]) {
                  newItem.time = times[1];
                  if (times[1].includes("(")) {
                    let extraNew = times[1].split("(");
                    newItem.time = extraNew[0];
                    newItem.title = newItem.title.trim() + " (" + extraNew[1];
                  }
                  extraItems.push(newItem);
                }
              } catch (error) {
                //do nothingk;
              }
            }
          } catch (error) {
            //do nothing;
          }
        });

        items = [...items, ...extraItems];

        items.forEach(item => {
          try {
            if (item.time) {
              item.time = item.time.trim();
            }
          } catch (error) {
            //do nothing;
          }

          try {
            if (item.title) {
              item.title = item.title.trim();
            }
          } catch (error) {
            //do nothing;
          }
          try {
            if (item.title && item.time) {
              item.main = `Keeper Talk | ${item.time} | ${item.title}`;
            }
          } catch (error) {
            //do nothing;
          }

          try {
            let parsedTime = moment(item.time, "h:mma");

            item.timeAct = parsedTime.toDate();
            try {
              item.timeInMins = parsedTime.hours() * 60 + parsedTime.minutes();
            } catch (error) {
              //console.log("error!!!", error);
            }
          } catch (error) {
            //do nothing;
            //              console.log("error!!!", error);
          }
        });

        try {
          items.sort((a, b) => a?.timeInMins - b?.timeInMins);
        } catch (error) {
          //do nothing;
        }

        if (items.length) {
          catList[catSlug].items = items;
        }
      });

      for (const [key, value] of Object.entries(catList)) {
        if (!value?.items?.length) {
          delete catList[key];
        }
      }
      return catList;
    }
  },
  data: () => ({
    selectedItem: null
  }),
  methods: {
    panToMaker(site) {
      try {
        let section_slug = site?.properties?.section_slug;
        this.$store.dispatch("filters_enableLayer", section_slug);

        try {
          let slug = site?.properties?.slug;

          window.mvAnalyticEvent(
            "ui",
            "signListClick",
            `${section_slug}_${slug}`,
            false
          );
        } catch (error) {
          //do nothing;
        }
      } catch (error) {
        //do nothing;
      }

      setTimeout(() => {
        //if(!this)
        eventBus.$emit("nav_map_flyToFeature", site);
      }, 1);

      setTimeout(() => {
        this.selectedItem = null;
      }, 300);
    }
  },
  created() {},
  watch: {
    /*
    sidebarSite(newValue, oldValue) {
      this.sidebarSiteAct = newValue;

      if (newValue) {
        this.scrollToSite(newValue);
      } else {
        this.scrollToTopIfNull();
      }
    }*/
  },

  async mounted() {}
};
</script>
<style>
.sinageListWrapper {
  margin-bottom: 75px;
}

.signListAvatar {
  margin-top: 0;
  margin-bottom: 0;
}
.signListAvatar img {
  xbackground: grey;

  width: 30px;
  object-fit: contain;
  overflow: visible;
}

@media only screen and (max-width: 1263px) {
  .sinageListWrapper {
    margin-bottom: 150px;
  }
}
</style>
