<template>
  <v-toolbar-title class="mvTitleBar" :title="`${siteObject.title} Maptivate`">
    <div class="inline d-flex align-center">
      <img
        v-if="appSettings.titleImageLogo === true"
        class="titleLogo "
        :src="
          `./assets/logos/${siteObject.key}_${
            appSettings.titleImageLogoColor ? 'color' : 'white'
          }.png`
        "
      />
    </div>
    <div class="inline d-flex align-center">
      <div
        v-if="
          !$vuetify.breakpoint.xsOnly || !appSettings.titleImageLogo === true
        "
        :class="
          `${
            appSettings.titleTextColorClass
              ? appSettings.titleTextColorClass
              : ''
          }`
        "
      >
        <font
          class="font-weight-medium"
          v-if="!appSettings.titleImageLogo === true"
          >{{ siteObject.title }}</font
        >
        <font class="pl-1 font-weight-medium" v-if="false && !$vuetify.breakpoint.xsOnly"
          >Maptivate</font
        >
      </div>
    </div>
  </v-toolbar-title>
</template>

<script>
export default {
  name: "engAndTrans",

  props: {
    siteObject: Object,
    appSettings: Object
  }
};
</script>
<style>
.mvTitleBar img {
  padding: 4px;

  max-height: 40px;
}

.mvTitleBar {
  max-height: 55px;
}

.titleLogo {
  max-height: 55px;
}

.inline {
  float: left;
  height: 55px;
  xbackground: red;
  vertical-align: center;
}
</style>
