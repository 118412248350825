<template>
  <center id="topOfHelpSection">
    <v-card-title id="helpHeading_nav" class="justify-center"
      >How do I navigate around the map on my phone?
    </v-card-title>

    <v-card-text>
      Use two fingers to pinch and zoom in and out. <br />
      Use one finger to navigate around the map.
    </v-card-text>
    <br />
    <br />
    <v-divider></v-divider>
    <v-card-title id="helpHeading_gps" class="justify-center"
      >How do I find my location in the zoo?
    </v-card-title>
    <v-card-text>
      Tap the location button on the left-hand side. If prompted, you may need
      to allow location services on your phone. This information is
      <u>not</u> collected nor retained by Zoos Victoria or Maptivate.
    </v-card-text>

    <img :src="`/assets/help/${getImage('nav')}`" />

    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="helpHeading_search" class="justify-center"
      >How do I search for an animal or place?
    </v-card-title>
    <v-card-text
      >Open the menu by clicking the three white lines in the top corner of the
      map then find the yellow ‘List Filter’ search bar and type in what you’re
      looking for:
    </v-card-text>
    <img :src="`/assets/help/${getImage('filter')}`" />

    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="helpHeading_sensory" class="justify-center"
      >How can I view the sensory awareness filter?
    </v-card-title>
    <v-card-text
      >Open the Layer Filters menu by clicking the layer icon in the top corner
      of the map (green with a black square and line inside it). Note:
      description of each sensory icon is displayed in the Map Legend in the
      bottom right corner of the screen.
    </v-card-text>
    <img :src="`/assets/help/${getImage('layers')}`" class="small" />

    <v-card-text>Select the Sensory Awareness layer:</v-card-text>
    <img :src="`/assets/help/${getImage('sensory')}`" />

    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="helpHeading_keeper_talks" class="justify-center"
      >How can I see a list of Keeper Talk times?
    </v-card-title>
    <v-card-text
      >Open the menu by clicking the three white lines in the top corner of the
      map and select Keeper Talks to see a list of clickable talk times and
      locations:
    </v-card-text>

    <img :src="`/assets/help/${getImage('keepertalks')}`" />

    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="helpHeading_language" class="justify-center"
      >How do I translate the map into another language?
    </v-card-title>
    <v-card-text
      >Click on the translation button in the top left of the screen to see a
      list of languages to choose from.
    </v-card-text>

    <img :src="`/assets/help/${getImage('translate')}`" />

    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="helpHeading_legend" class="justify-center"
      >What do the symbols on the map mean and how can I view the map legend?
    </v-card-title>
    <v-card-text
      >Click on the map symbol in the bottom right of the screen to bring up the
      legend.
    </v-card-text>

    <img :src="`/assets/help/${getImage('legend')}`" class="small" />

    <br />
    <br />
    <br />
    <br />

    <v-divider></v-divider>
    <v-card-title id="helpHeading_pdf" class="justify-center"
      >Where can I find a PDF map?
    </v-card-title>
    <v-card-text
      >You can download a PDF map here:

      <maptivateInfoButton
        :width="'200px'"
        :color="appSettings.mediaButtonColor"
        icon="mdi-file-document-outline"
        title="Download PDF MAP"
        target="_blank"
        :href="siteObject.mapurl"
      />
    </v-card-text>

    <br />
    <br />
  </center>
</template>

<script>
import { mapState } from "vuex";
import maptivateInfoButton from "../../ui/maptivateInfoButton";

export default {
  name: "FAQ_Modal_General",
  props: {
    siteId: String
  },

  components: {
    maptivateInfoButton
  },
  data() {
    return {};
  },
  methods: {
    getImage(title) {
      if (this.siteId === "mz") {
        return `/${this.siteId}/${title}.png`;
      } else {
        return `/JPEG/${this.siteId}_${title}.jpg`;
      }
    }
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      siteObject: state => state.global.siteObject
    })
  },
  created() {}
};
</script>

<style>
#topOfHelpSection img.small {
  max-width: 120px;
}
</style>
